import React from "react";

function Footer() {
    return (
        <div className="Footer">

            <div className="wrapper wrapper_flex">
                {/*<div><img src="url/to/logo.png" alt="Logo"/></div>*/}
                <div>ABT</div>
                <div>АДРЕСА</div>
                <div>ТЕЛЕФОН</div>
            </div>
        </div>
    )
}

export default Footer;
